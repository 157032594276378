import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import VideoComponent from "../components/videos"

import "../assets/css/main.scss"

const HeatRecovery = () => (
  <Layout>
    <StaticQuery
      query={graphql`
      query {
      
        allStrapiVideo {
          edges {
            node {
              strapiId
              url               
              isShowing
              isHeatRecovery
              loop              
              Button {
                id
                type
                text
                left
                top
                redirectTo
                buttonIcon
                redirectUrl
              }
              title
              description
              showMenu
              showMenuButtons   
            }
          }
        }
      }
    `}

      render={(data, index) => (
        <div>
          <VideoComponent videos={data.allStrapiVideo.edges} room="heatrecovery" key={index}/>
        </div>
      )}  
    />
  </Layout>

)

export default HeatRecovery